<template>
    <div class="box">
        <div class="background"></div>
        <div class="homepage-header flex x-left">
            <Header type="2" :navList="navList" :navCurrentId="navCurrentId">
                <div slot="search">
                    <div style="width:200px;"></div>
                </div>
            </Header>
        </div>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="homepage-left flex x-left">
                        <!-- 直播推荐 -->
                        <div class="recommend-first" v-if="recommendFirst" @click="liveJumpDetail(recommendFirst.live_id)">
                            <img style="object-fit:cover;" class="rf-image" :src="recommendFirst.live_banner | urlFilter(750)"
                                alt="直播标题">
                            <div v-if="recommendFirst.title" :title="recommendFirst.title" class="row-1 colorfff rf-title fs12 pl10 pr10 t-l">{{recommendFirst.title}}</div>
                            <div class="rf-info flex y-center">
                                <div v-if="recommendFirst.status == 0" class="t-l">
                                    <div class="rf-info-text fs12 colorfff">距离直播还有</div>
                                    <div class="rf-info-time">
                                        <b>{{countdownTime.D}}</b>
                                        <sub v-if="countdownTime.D > 0">天</sub>
                                        <b>{{countdownTime.H}}</b>
                                        <sub v-if="countdownTime.D > 0">时</sub>
                                        <b>{{countdownTime.M}}</b>
                                        <sub v-if="countdownTime.D > 0">分</sub>
                                        <b>{{countdownTime.S}}</b>
                                        <sub v-if="countdownTime.D > 0">秒</sub>
                                    </div>
                                </div>
                                <div v-else class="fs16 colorfff flex-item t-l pr30 row-2">{{recommendFirst.title}}
                                </div>
                                <!-- 预约直播 -->
                                <div v-if="recommendFirst.status == 0" @click.stop="appointLive">
                                    <div class="rf-info-btn live-start" v-if='recommendFirst.applet_remind == 0'>预约直播</div>
                                    <div class="rf-info-btn live-appointed" v-if='recommendFirst.applet_remind == 1'>已预约</div>
                                </div>
                                <!-- 直播中 -->
                                <div v-else-if="recommendFirst.status == 1" class="rf-info-btn living live-status">
                                    <b></b><b></b><b></b><b></b>直播中
                                </div>
                                <!-- 观看回放 -->
                                <div v-else class="rf-info-btn lived">观看回放</div>
                            </div>
                        </div>
                        <a-empty style="margin-top:50px;" class="recommend-first" v-if="!recommendFirst && !loading" />
                        <div v-if="informationList.length" class="hlf-news ml20 flex-item t-l">
                            <router-link :to="{path:'/organization/org-info-detail',query:{id:informationList[0].id,type:informationList[0].type}}">
                                <div :title="informationList[0].news_title" class="strong fs20 color333 row-2">
                                    {{informationList[0].news_title}}
                                </div>
                                <!-- <div class="fs14 color999 mt5 row-1">
                                    {{informationList[0].keywords}}
                                </div> -->
                            </router-link>
                            <router-link v-for="(item, index) in informationList.slice(1,4)" :key="index" class="hlf-news-item color333 fs16 row-2 mt15"  :to="{path:'/organization/org-info-detail',query:{id:item.id,type:item.type}}">
                                <span :title="item.news_title">{{item.news_title}}</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="mt40 album-list" v-if="albumList.length">
                        <common-title path="/organization/org-album" title="会议相册"></common-title>
                        <album-item :data-list="albumList"></album-item>
                    </div>
                </a-spin>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { orgDetailList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import albumItem from '@/components/album-item';
import { liveJumpDetail } from '@/utils/jumpPageMethods';
import { dateFormat, UTCToLocalTimeString } from '@/utils/dateFormat.js';
import storage from 'store';

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, commonTitle, albumItem
    },
    data() {
        return {
            // committee_id:this.$route.query.id,
            countdownTime:{},
            orgContent:{}, //机构内容
            navList: orgDetailList(this.$route.query.committee_id),//顶部菜单
            navCurrentId: 0,//当前页面菜单对应下标
            recommendFirst: null, // 直播特别推荐
            informationList:[], //资讯列表
            albumList:[], //会议相册
            loading: true,
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '即将召开会议', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-meeting?committee_id=' + this.$route.query.committee_id,
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                        committee_id:this.$route.query.committee_id
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '会议视频回放', // 标题
                    methods: 'get',
                    moreLink:'/organization/org-playback?committee_id=' + this.$route.query.committee_id,
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id_search: this.$route.query.committee_id,
                    },   // 参数条件
                },

            },
        }
    },
    created() {
        storage.set('commiittee_id',this.$route.query.committee_id)
        this.pageInit()
    },
    methods: {
        liveJumpDetail,
        // 获取推荐直播
        getCommandLiveList() {
            let data = {
                page: 1,
                limit: 1,
                committee_id: this.$route.query.committee_id,
                no_end_time: 1,
            }
            return this.request.get('GetLiveList', data)
        },
        getInfomation(){
            return this.request.post('GetNews',{
                committee_id: this.$route.query.committee_id,
                limit: 7,
                page: 1,
            })
        },
        getAlbumList(){
            return this.request.post('MeetingPictureList',{
                committee_id: this.$route.query.committee_id,
                limit: 2,
                page: 1
            })
        },
        // 判断特别推荐的直播状态
        setLiveStatus() {
            let nowTime = UTCToLocalTimeString(new Date()).getTime();
            let startTime = this.recommendFirst.start_time.replace(/-/g, '/');
            let endTime = this.recommendFirst.end_time.replace(/-/g, '/');
            endTime = endTime.slice(0,10) + ' 23:59:59'
            startTime = +(new Date(startTime));
            endTime = +(new Date(endTime));
            if (startTime > nowTime) {
                // 未开始
                this.$set(this.recommendFirst, 'status', 0);
                // 获取倒计时
                let countdownTime = dateFormat(startTime - nowTime);
                let countdownTimeArr = countdownTime.split(',')
                let D = countdownTimeArr[0];
                let timeArr = countdownTimeArr[1].split(':');
                let H = timeArr[0];
                let M = timeArr[1];
                let S = timeArr[2];
                this.countdownTime = { D, H, M, S }
            } else if (nowTime > startTime && endTime > nowTime && this.recommendFirst.is_live_in == '1') {
                // 直播中
                this.$set(this.recommendFirst, 'status', 1);
                clearInterval(this.recommendFirst.timer);
            } else {
                // 已结束
                this.$set(this.recommendFirst, 'status', 2);
                clearInterval(this.recommendFirst.timer);
            }
        },
        pageInit() {
            this.loading = true
            let reqArr = [
                this.getCommandLiveList(),
                this.getInfomation(),
                this.getAlbumList(),
            ]
            Promise.all(reqArr).then(res => {
                // 推荐直播
                this.recommendFirst = res[0].data.list[0]
                if(this.recommendFirst){
                    this.setLiveStatus();
                    !this.recommendFirst.timer && (this.recommendFirst.timer = setInterval(this.setLiveStatus, 1000));
                }
                

                // 资讯
                this.informationList = res[1].data.list

                // 会议相册
                this.albumList = res[2].data.slice(0,2)
                this.loading = false
            }).catch((err) => { console.log(err) })
        },
        // 预约直播
        appointLive(){
            let { live_id } = this.recommendFirst
            this.request.post('AppletAppointment',{
                pages_url: "/live-pages/live-play/live-play?id=" + live_id,
                type: 1,
                type_id: live_id,
                tj_token: storage.get('tid')
            }).then(res=>{
                this.recommendFirst.applet_remind = 1
            })
        }
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    .mh{
        min-height: 500px;
    }
}
.homepage-right {
    width:400px;
}
// 直播推荐
.recommend-first {
    width: 50%;
    cursor: pointer;
    position: relative;
    .rf-image {
        width: 100%;
        height: 208px;
    }
    .rf-title{
        position: absolute;
        bottom: 67px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 35px;
        line-height: 35px;
        box-sizing: border-box;
    }
    .rf-info {
        height: 67px;
        padding: 10px 20px;
        box-sizing: border-box;
        background: #1c2769;

        .rf-info-time {
            margin-top: 5px;

            b {
                font-size: 18px;
                color: #ffa45d;
                font-weight: bold;
                background: #2f3c8a;
                border-radius: 5px;
                padding: 3px;
            }

            sub {
                font-size: 12px;
                color: #fff;
                margin: 0 5px;
                opacity: 0.7;
            }
        }

        .rf-info-btn {
            padding: 6px 15px;
            border-radius: 35px;
            line-height: 20px;
            height: 32px;
            font-size: 12px;
            color: #fff;
        }

        .live-start {
            background: linear-gradient(to right, #f5bf58, #f2873b);
        }

        .living {
            background: linear-gradient(to right, #5f96e6, #1e51c9);
        }

        .lived {
            background: linear-gradient(to right, rgb(87, 188, 211), #1eabc9);
        }
        .live-appointed{
            background: #cccccc;
        }
    }
}
.hlf-news{
    width: 325px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 0;
        width: 100%;
        .homepage-list{
            display: block;
            margin-right: 0;
            .homepage-left{
                display: block;
                .recommend-first{
                    width: 100%;
                    .rf-image{
                        height: auto;
                        object-fit: contain!important;
                    }
                }
                .hlf-news{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 10px;
                    padding: 0 10px;
                }
            }
            .album-list{
                margin-top: 20px;
                padding: 0 10px;
            }
        }
        .homepage-right{
            width: 100%;
            padding: 0 10px;
            margin-top: 50px;
        }
    }
}
</style>